import React from 'react';

import Layout from '~/layouts/main';
import SEO from '~/components/seo';
import { JoinClassModal } from '~/components/modals';

const JoinClass: React.FC = () => {
  return (
    <Layout isheader={false} isfooter={false}>
      <SEO title="Join class" />
      <JoinClassModal />
    </Layout>
  );
};

export default JoinClass;
