import React from 'react';

import AppWrapper from './app-wrapper';

import Header from '~/components/header';
import Footer from '~/components/footer';


interface LayoutProps {
  isheader?: boolean;
  isfooter?: boolean;
}

const MainLayout: React.FC<LayoutProps> = ({
  children,
  isheader = true,
  isfooter = true,
}) => (
  <AppWrapper className="flex flex-col min-h-screen">
    {isheader &&
      <div className="w-full">
        <Header isDashboard={false} />
      </div>
    }
    <main className="flex-grow">{children}</main>
    {isfooter &&
      <div className="w-full">
        <Footer />
      </div>
    }
  </AppWrapper>
);

export default MainLayout;
